<template>
  <div class="wrap">
    <el-button-group>
      <el-button type="primary" icon="el-icon-edit" @click="showAll"
        >查看我创建的问卷列表</el-button
      >
      <el-button type="success" icon="el-icon-edit" @click="showMyAns"
        >查看我回答的问卷列表</el-button
      >
      <el-button type="danger" icon="el-icon-edit" @click="showMyCheck"
        >查看我批改的问卷列表</el-button
      >
    </el-button-group>
  </div>
</template>

<script>
export default {
  methods: {
    showAll() {
      this.$router.push({
        path: "/formList",
        query: { type: "all", createId: this.$route.params.userid }
      });
    },
    showMyAns() {
      this.$router.push({
        path: "/formList",
        query: { type: "myAns", createId: this.$route.params.userid }
      });
    },
    showMyCheck() {
      this.$router.push({
        path: "/formList",
        query: { type: "myCheck", createId: this.$route.params.userid }
      });
    }
  }
};
</script>

<style>
.wrap {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  margin-top: 100px;
}
</style>
